import moment from 'moment'

$(() => {
  if (!$('.js-form-validation').length) {
    return
  }

  moment.locale('ja')

  // 1つ以上エラーがあるかフラグ
  let formValidedAtLeastOnce = false
  // サブミットボタンの文言をキャッシュ
  let cacheSubmitButtonText = ''

  let validForForm = (form) => {
    formValidedAtLeastOnce = true
    // エラーなしか判定
    let noError = true
    // バリデーションするセレクタ
    let validateSelectors = []
    validateSelectors.push('input[type="text"]')
    validateSelectors.push('input[type="password"]')
    validateSelectors.push('input[type="email"]')
    validateSelectors.push('input[type="url"]')
    validateSelectors.push('input[type="number"]')
    validateSelectors.push('input[type="month"]')
    validateSelectors.push('input[type="date"]')
    validateSelectors.push('input[type="datetime-local"]')
    // validateSelectors.push('input[type="file"]') fileはバリデーションしない
    validateSelectors.push('input[type="hidden"]')
    validateSelectors.push('input[type="checkbox"]')
    validateSelectors.push('select')
    validateSelectors.push('textarea')
    let validateTargets = validateSelectors.map((sel) => {
      return sel
    }).join(",")
    // ターゲットごとにバリデーション
    $(form).find(validateTargets).each((i, el) => {
      let valid
      valid = validForField(el)
      if (!valid) {
        noError = false
      }
    })
    return noError
  }

  let validForField = (el) => {
    let skip = $(el).closest('form .js-validate-skip').length > 0
    if (skip) {
      return true
    }
    let rules = $(el).data('rules')
    let errorMessages = $(el).data('error-messages')
    let value = $(el).val()
    if (!rules) {
      return true
    }
    removeError(el)
    for (let j = 0, len = rules.length; j < len; j++) {
      let rule = rules[j]
      // 個別のエラーメッセージ
      let errorMessage = !!errorMessages ? errorMessages[rule] : null;
      switch (rule) {
        case 'presence':
          if (!validPresent(value, el)) {
            if (!!errorMessages && !!errorMessages.presence) {
              addError(el, errorMessages.presence)
            } else if ($(el).prop('tagName') === 'SELECT') {
              addError(el, '値を選択してください')
            } else if ($(el).prop('type') === 'hidden' && /.*file.*/.test($(el).prop('name'))) {
              addError(el, 'ファイルを選択してください')
            } else if ($(el).prop('type') === 'hidden' && /.*payment_service_card_index.*/.test($(el).prop('name'))) {
              addError(el, 'クレジットカードを選択してください')
            } else {
              addError(el, '値を入力してください')
            }
            return false
          }
          break
        case 'presence-checkbox':
          if (!validPresentCheckbox(value, el)) {
            if (!!errorMessages) {
              addParentError(el, errorMessages)
            } else {
              addParentError(el, '値を入力してください')
            }
            return false
          }
          break
        case 'max':
          if (!validMax(value, el)) {
            let max = $(el).data('max')
            addError(el, `${max}文字以内で入力して下さい`)
            return false
          }
          break
        case 'min':
          if (!validMin(value, el)) {
            let min = $(el).data('min')
            addError(el, `${min}文字以上で入力して下さい`)
            return false
          }
          break
        case 'over':
          if (!validOver(value, el)) {
            let over = $(el).data('over')
            addError(el, `${over}以上を入力して下さい`)
            return false
          }
          break
        case 'less':
          if (!validLess(value, el)) {
            let less = $(el).data('less')
            addError(el, `${less}以下を入力して下さい`)
            return false
          }
          break
        case 'before':
            if (!validBefore(value, el)) {
              addError(el, errorMessages.before)
              return false
            }
            break
        case 'after':
          if (!validAfter(value, el)) {
              addError(el, errorMessages.after)
            return false
          }
          break
        case 'reverse':
            if (!validReverse(value, el)) {
              let label = $($(el).data('reverse')).closest('.form-group').find('label').text()
              addError(el, `${label}より後の日程を選択してください`)
              return false
            }
            break
          case 'reverse-before':
            if (!validReverseBefore(value, el)) {
              let label = $($(el).data('reverse-before')).closest('.form-group').find('label').text()
              addError(el, `${label}より前の日程を選択してください`)
              return false
            }
            break
        case 'email':
          if (!validEmail(value)) {
            addError(el, 'メールアドレスの形式が不正です')
            return false
          }
          break
        case 'tel':
          if (!validTel(value, el)) {
            addError(el, `電話番号の形式が不正です`)
            return false
          }
          break
        case 'url':
          if (!validUrl(value)) {
            addError(el, 'URlの形式が不正です')
            return false
          }
          break
        case 'password':
          if (!validPassword(value)) {
            addError(el, '半角英数字の組み合わせで入力して下さい')
            return false
          }
          break
        case 'json':
          if (!validJson(value)) {
            addError(el, 'JSON形式で入力して下さい')
            return false
          }
          break
        case 'kana':
          if (!validKana(value, el)) {
            addError(el, `カタカナで入力して下さい`)
            return false
          }
          break
        case 'romaji':
            if (!validRomaji(value, el)) {
              addError(el, `ローマ字で入力して下さい`)
              return false
            }
            break
        case 'romaji_upper':
          if (!validRomajiUpper(value, el)) {
            addError(el, `ローマ字(大文字)で入力して下さい`)
            return false
          }
          break
        case 'regex':
          if (!validRegex(value, el)) {
            addError(el, errorMessage || `形式に誤りがあります`)
            return false
          }
          break
        case 'past':
            if (!validPast(value)) {
              addError(el, `過去の日付は選択できません`)
              return false
            }
            break
        case 'available_email_domain':
            if (!validAvailableEmailDomain(value, el)) {
              let domains = $(el).data('available-domains')
              addError(el, `このメールアドレスは使用できません。${domains}で終わるメールアドレスのみご利用可能です`)
              return false
            }
            break
        case 'forbidden_email_domain':
            if (!validForbiddenEmailDomain(value, el)) {
              let domain = '@' + value.split('@').slice(-1)[0];
              addError(el, `${domain}は利用できません`)
              return false
            }
            break
      }
    }
    return true
  }
  let validPresent = (value, el) => {
    return !!value
  }

  let validPresentImage = (value, el) => {
    // ImageFileInputでのみ使用されている
    return !!value
  }

  let validPresentCheckbox = (value, el) => {
    return $(el).is(":checked")
  }

  let validMax = ({ length }, el) => {
    let max = $(el).data('max')
    return length <= parseInt(max)
  }

  let validMin = ({ length }, el) => {
    let min = $(el).data('min')
    return length >= parseInt(min)
  }

  let validOver = (value, el) => {
    return value >= $(el).data('over')
  }

  let validLess = (value, el) => {
    return value <= $(el).data('less')
  }

  let validBefore = (value, el) => {
    if (!value) {
      return true
    }

    // Moment.jsで10000年以降を指定しても4桁に丸められてしまうのでVanilla JSで実装(ISO8601準拠)
    // moment('10000-01-01', 'YYYY-MM-DDTHH:mm').year() -> 1000
    const val = new Date(value)
    const to = new Date($(el).data('before'))
    return val <= to
  }

  let validAfter = (value, el) => {
    if (!value) {
      return true
    }

    // Moment.jsで10000年以降を指定しても4桁に丸められてしまうのでVanilla JSで実装(ISO8601準拠)
    // moment('10000-01-01', 'YYYY-MM-DDTHH:mm').year() -> 1000
    const val = new Date(value)
    const from = new Date($(el).data('after'))
    return val >= from
  }

  let validReverse = (value, el) => {
    if (!value) {
      return true
    }
    let target = $($(el).data('reverse')).val()
    if (!target) {
      return true
    }

    let time = moment(value, "YYYY-MM-DDTHH:mm")
    let targetTime = moment(target, "YYYY-MM-DDTHH:mm")
    return time.isSameOrAfter(targetTime)
  }

  let validReverseBefore = (value, el) => {
    if (!value) {
      return true
    }
    let target = $($(el).data('reverse-before')).val()
    if (!target) {
      return true
    }

    let time = moment(value, "YYYY-MM-DDTHH:mm")
    let targetTime = moment(target, "YYYY-MM-DDTHH:mm")
    return time.isSameOrBefore(targetTime)
  }

  let validTel = (value, el) => {
    if (!value) {
      return true
    }
    if (/-/.test(value)) {
      value = value.replace(/-|[a-z]/g, '')
      $(el).val(value)
    }
    let regex = /^\d{10}$|^\d{11}$/
    return regex.test(value)
  }

  let validEmail = (value) => {
    if (!value) {
      return true
    }
    let regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return regex.test(value)
  }

  let validUrl = (value) => {
    let regex = /^(?:[^:\/?#]+:)?(?:\/\/[^\/?#]*)?(?:([^?#]*\/)([^\/?#]*))?(\?[^#]*)?(?:#.*)?$/
    return regex.test(value)
  }

  let validRegex = (value, el) => {
    if (!value) {
      return true
    }
    let regex =$(el).data('format')
    return new RegExp(regex).test(value)
  }

  let validPassword = (value) => {
    let regex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/i
    return regex.test(value)
  }

  let validJson = (value) => {
    if (!value) {
      return true
    }
    try {
      JSON.parse(value)
    } catch(e) {
      return false
    }
      return true
  }

  let validKana = (value, el) => {
    return !!value.match(/^[ァ-ヶ０-９0-9ー\-　 ]*$/)
  }

  let validRomaji = (value, el) => {
    return !!value.match(/^[a-zA-Z０-９0-9ー\-　 ]*$/)
  }

  let validRomajiUpper = (value, el) => {
    return !!value.match(/^[A-Z０-９0-9ー\-　 ]*$/)
  }

  let validPast = (value) => {
    if (!value) {
      return true
    }
    let val = moment(value)
    let now = moment(new Date())
    return val.isSameOrAfter(now)
  }

  let validAvailableEmailDomain = (value, el) => {
    if (!value) {
      return true
    }
    if (!validEmail(value)) {
      return true
    }
    let domains = $(el).data('available-domains')
    if (!domains) {
      return true
    }
    if (domains.length == 0) {
      return true
    }
    for (let i = 0; i < domains.length; i++) {
      if (value.endsWith(`@${domains[i]}`)) {
        return true
      } else if(domains[i] == 'ac.jp' && value.endsWith('.ac.jp')) {
        return true
      } else if(domains[i] == 'jp' && value.endsWith('.jp')) {
        return true
      } else if(domains[i] == 'com' && value.endsWith('.com')) {
      }
    }
    return false
  }

  let validForbiddenEmailDomain = (value, el) => {
    if (!value) {
      return true
    }
    if (!validEmail(value)) {
      return true
    }
    let domains = $(el).data('forbidden-domains')
    for (let i = 0; i < domains.length; i++) {
      if (value.endsWith(`@${domains[i]}`)) {
        return false
      }
    }
    return true
  }

  let addError = (el, message) => {
    $(el).addClass('is-invalid')
    $(el).closest('.form-group').addClass('is-invalid')
    $(el).addClass('border-danger')
    return $(el).after(`<div class="invalid-feedback">${message}</div>`)
  }

  let addParentError = (el, message) => {
    $(el).addClass('is-invalid')
    $(el).closest('.form-group').addClass('is-invalid')
    $(el).addClass('border-danger')
    return $(el).parent().after(`<div class="invalid-feedback">${message}</div>`)
  }

  let removeError = (el) => {
    $(el).removeClass('is-invalid')
    $(el).closest('.form-group').removeClass('is-invalid')
    $(el).removeClass('border-danger')
    $(el).parent().parent().children().remove('.invalid-feedback')
    return $(el).parent().children().remove('.invalid-feedback')
  }

  // フォーム変更検知
  let onChangeField = (el) => {
    // console.log('onChange')
    let form = $(el.target).closest('form')
    setTimeout(() => {
      if (formValidedAtLeastOnce) {
        validForForm(form)
      } else {
        validForField(el.target)
      }
      afterValid(form)
    }, 300)
  }

  let afterValid = (form) => {
    let hasError = $(form).find('.is-invalid').length > 0

    if (hasError) {
      disavailableSubmit(form)
    } else {
      availableSubmit(form)
    }
  }

  let scrollFirstError = (form) => {
    if (!$(form).find('.is-invalid').length) {
      return
    }
    const offset = 100;
    const position = $(form).find('.is-invalid').offset().top;
    const speed = 200;
    $("html,body").animate({ scrollTop: position - offset}, speed);

  }

  let submit = (e) => {
    // ローディング開始
    setBtnLoading(e.target)

    setTimeout(() => {
      let form = $(e.target).closest('form')

      validForForm(form)
      afterValid(form)

      $(form).find('.js-validate-skip .is-invalid').removeClass('is-invalid')

      if ($(form).find('.is-invalid').length > 0) {
        scrollFirstError(form)
        stopBtnLoading(e.target)
        return
      }

      beforeSubmit(form)

      // 下書き保存でない場合の処理
      if ($(e.target).attr('name') == 'revision') {
        $('.js-form-validation').append('<input type="hidden" name="revision" value="true" />')
      }

      form.submit()
    }, 100)
  }

  let setBtnLoading = (el) => {
    let submitBtn = $(el)
    submitBtn.prop('disabled', true)
    submitBtn.addClass('disabled')
    cacheSubmitButtonText = submitBtn.text()
    submitBtn.text('')
    return submitBtn.append(
      '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    )
  }

  let stopBtnLoading = (el) => {
    let submitBtn = $(el)
    submitBtn.prop('disabled', false)
    submitBtn.removeClass('disabled')
    submitBtn.text(cacheSubmitButtonText)
  }

  let availableSubmit = (form) => {
    $(form).find('.submit').prop('disabled', false)
    $(form).find('.submit').removeClass('disabled')
  }

  let disavailableSubmit = (form) => {
    $(form).find('.submit').prop('disabled', true)
    $(form).find('.submit').addClass('disabled')
  }

  let beforeSubmit = (form) => {
    // submitをdisable
    disavailableSubmit(form)
    // toggle_trigger_keyがある場合はsubmit前に値を消す
    $('.js-toggle-form').each((i, elm) => {
      let input = $(elm).find('input,select,textarea')
      let name = input.attr('name')
      let triggerKey = $(elm).data('toggle-trigger-key')
      let triggerName = name.replace(/\[(?!.*\[).*/, '[' + triggerKey + ']')
      let trigger = form.find('input[name="' + triggerName + '"],select[name="' + triggerName + '"]')
      let viewableLabels = $(elm).data('toggle-viewable-label').split(',')
      // radio
      let checkedLabel = trigger.closest('.form-group').find('input:checked').closest('span').find('label').text()
      if (!checkedLabel) {
        // select
        checkedLabel = trigger.closest('.form-group').find('option:selected').text()
      }
      if (!viewableLabels.includes(checkedLabel)) {
        input.val('')
      }
    })
  }

  // フォーム変更
  // VueのフォームはハッカしないのでvalidFormのタイミングで適当なフォームにjqueryイベントを発生させる。
  // ex)
  // new Vue({
  //   el: id,
  //   data: {
  //     rules: $(id).data('rules'), // Validルール
  //     errors: [], // Validエラーメッセージ
  //   },
  //   mounted() {
  //     $('.js-form-validation .submit').on('click', (e) => {
  //       this.validForm()
  //     })
  //   },
  //   methods: {
  //     validForm() {
  //       if (!this.rules || this.rules.length == 0) {
  //         // 未定義はリターン
  //         return
  //       }
  //       this.errors = []
  //       if (!this.url || this.url == '') {
  //         this.errors.push("ファイルを選択してください")
  //       }
  //       $(id).find('input').trigger('change')
  //     },
  //     onValueUpdate() {
  //       this.validFrom()
  //     }
  //   }
  // })
  //
  //
  // .my-3.invalid-feedback.is-invalid v-for="error in errors"
  //   | {{error}}
  //
  $('.js-form-validation').on('blur keyup change', 'input, select, textarea', (el) => {
    onChangeField(el)
  })

  // フォーム変更検知(js-multile-checkbox)
  $('.js-form-validation').on('click', '.js-multiple-checkbox .checkbox, .js-hierarchy-checkbox .checkbox', (el) => {
    onChangeField(el)
  })

  // フォーム削除検知(coccon)
  // 削除の検知ができず、submitが非活性になるのを防ぐ
  $('.js-form-validation').on('click', '.js-cocoon-remove', (e) => {
    let form = $(e.target).closest('form')
    afterValid(form)
  })

  // サブミット
  $('.js-form-validation .submit').on('click', (e) => {
    e.preventDefault()

    submit(e)
  })

  // 下書き
  $('.js-form-validation .draft').on('click', ({ target }) => {
    setBtnLoading(target)
    let form = $(target).closest('form')
        $('.js-form-validation').append('<input type="hidden" name="revision" value="true" />')
    form.submit()
  })
})
