$(() => {
  if (!$('#contacts_new').length) {
    return
  }


  $("#contact_contact_type").on('change', (e) => {
    let self = $(e.target)
    let selectedValue = self.val()
    let config = self.data('config')
    for (let conf of config) {
      if (conf[0] == selectedValue) {
        $("#contact_body").val(conf[2])
      }
    }
  })
})
