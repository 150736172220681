$(() => {
  if (!$('.js-toggle-form').length) {
    return
  }

  let setup = (elm) => {
    let form = $(elm).closest('form')

    let input = $(elm).find('input,select,textarea')
    let name = input.attr('name')
    let key = name.match(/\[(?!.*\[).*/)[0].replace('[', '').replace(']', '')
    let triggerModel = $(elm).data('toggle-trigger-model')
    let triggerKey = $(elm).data('toggle-trigger-key')
    let triggerName = name.replace(/\[(?!.*\[).*/, '[' + triggerKey + ']')
    let viewableLabels = $(elm).data('toggle-viewable-label').split(',')
    let trigger = form.find('input[name="' + triggerName + '"],select[name="' + triggerName + '"]')
    let hint = form.find('.hint_' + triggerModel + '_' + key)

    let isAvailableForm = () => {
      // radio
      let checkedLabel = trigger.closest('.form-group').find('input:checked').closest('span').find('label').text()
      if (!checkedLabel) {
        // select
        checkedLabel = trigger.closest('.form-group').find('option:selected').text()
      }
      return viewableLabels.includes(checkedLabel)
    }

    let toggle = () => {
      if (isAvailableForm()) {
        $(elm).show()
        $(elm).removeClass('js-validate-skip')
        $(hint).show()
      } else {
        $(elm).hide()
        $(elm).addClass('js-validate-skip')
        $(elm).removeClass('is-invalid')
        $(elm).find('.is-invalid').removeClass('border-danger')
        $(elm).find('.is-invalid').removeClass('is-invalid')
        $(hint).hide()
      }
    }
    trigger.on('change', (e) => {
      toggle()
    })

    $('.submit').on('click', (e) => {
      if (isAvailableForm()) {
        return
      }
      // トルツメフォーム
      if (input.is("input") || input.is("textarea")) {
        input.val('')
      }
    })

    toggle()
  }
  

  $('.js-toggle-form').each((i, e) => {
    setTimeout(() => {
      setup(e)
    })
  })
})
