import Vue from 'vue'
import axios from 'axios'

$(() => {
  if (!$('#mypage-orders_index').length) {
    return
  }

  const id = '#js-mypage-orders-interview'
  new Vue({
    el: id,
    data: {
      orders: $(id).data('orders'),
      orderMessages: [],
      openOrder: null,
      // ステータス
      isLoadingMessages: true,
      isOpenMessageWindow: false,
      isOldestMessageShow: false,
      // form
      formBody: "",
      formFile: "",
      formFileName: ""
    },
    created: function() {
      let defaultOrderId = $(id).data('default-order-id')
      if (!!defaultOrderId) {
        for (let order of this.orders) {
          if (order.id == defaultOrderId) {
            this.onOpenMessageWindow(order)
          }
        }
      }
    },
    methods: {
      // *********************************************
      // helper
      // *********************************************
      dispBody(orderMessage) {
        return orderMessage.body.replace(/\r\n|\r|\n/g, "<br/>")
          .replace(/((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g, "<a target='_blank' href='$1' >$1</a>")
      },
      // byteを表示用の変換
      formatBytes(bytes, decimals = 1)  {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      },
      // 指定のメッセージまでスクロールする
      scrollToOrderMessage(orderMessageId) {
        setTimeout(() => {
          let windowTop = $(".mc-chat-message-window_body").scrollTop()
          let targetTop = $(`#message-${orderMessageId}`).position().top
          $(".mc-chat-message-window_body").animate({scrollTop: windowTop + targetTop}, 0, "swing");

        }, 0)
      },  
      // フォームをクリア
      clearForm() {
        this.formBody = ""
        this.formFile = ""
        this.formFileName = ""
      },
      clearFormFile() {
        this.formFile = ""
        this.formFileName = ""
      },
      // *********************************************
      // order message
      // *********************************************
      // OrderMessageをセットする
      setupOrderMessages() {
        let that = this
        that.isOldestMessageShow = false
        that.orderMessages = []
        this.getOrderMessages({
          order_id: that.openOrder.id,
        }, (orderMessages) => {
          this.pushOrderMessageConclusion()
          if (!that.openOrder.is_stop_create_order_message) {
            that.scrollToOrderMessage(that.orderMessages[that.orderMessages.length - 1].id)
          }
        })
      },
      onMoreOrderMessages() {
        let beforeHeight = $('html').get(0).scrollHeight
        let beforeScroll = window.scrollY
        // 取得
        this.getOrderMessages({
          order_id: this.openOrder.id,
          oldest_id: this.orderMessages[0].id
        }, () => {
          // // もっと見るを押した位置から動かさない
          // setTimeout(() => {
          //   let afterHeight = $('html').get(0).scrollHeight
          //   let diff = afterHeight - beforeHeight
          //   $(window).scrollTop(beforeScroll + diff, 1000)
          // }, 0)
        })
      },
      // OrderMessageの一覧を取得する
      getOrderMessages(params, callback) {
        this.isLoadingMessages = true
        axios
          .get('/api/v1/order_messages', {
            params: params
          })
          .then(resp => {
            this.isLoadingMessages = false
            // リスト処理
            for (let orderMessage of resp.data.order_messages) {
              this.pushOrderMessages(orderMessage)
            }
            callback && callback(resp.data.order_messages)
          })
      },
      // OrderMessageの一覧を取得する
      createOrderMessage(callback) {
        let that = this
        let formData = new FormData()
        formData.append('order_id', that.openOrder.id)
        formData.append('document_file', that.formFile)
        formData.append('file_name', that.formFileName)
        formData.append('body', that.formBody)
        if (!!that.formFileName && !that.formBody) {
          formData.append('body', "※ファイルを送信しました")
        }
        $.ajax({
          url: '/api/v1/order_messages',
          type: 'POST',
          data: formData,
          dataType: 'json',
          processData: false,
          contentType: false,
        }).done((data) => {
          // リスト処理
          let orderMessage = data.order_message
          that.pushOrderMessages(orderMessage)
          callback && callback(orderMessage)    
        })
        // フォームをリセット
        that.clearForm()
      },
      // メッセージをリストに追加
      pushOrderMessages(newOrderMessage) {
        // console.log(newOrderMessage)
        if (newOrderMessage.is_last) {
          // もっと見るを非表示に
          this.isOldestMessageShow = true
        }
        for(let i = 0; i < this.orderMessages.length; i++) {
          // 古いメッセージの場合
          if (this.orderMessages[i].id > newOrderMessage.id) {
            this.orderMessages.splice(i, 0, newOrderMessage)
            return
          }
        }
        // 最新のメッセージ
        this.orderMessages.push(newOrderMessage) 
      },
      // 最終メッセージを追加する
      pushOrderMessageConclusion() {
        this.orderMessages.push({
          is_conclusion: true,
        })
      },
      // *********************************************
      // イベント
      // *********************************************
      // メッセージウィンドウを開く
      onOpenMessageWindow(order) {
        if (!!this.openOrder && this.openOrder.id != order.id) {
          this.clearForm()
        }
        this.openOrder = order
        this.isOpenMessageWindow = true
        this.setupOrderMessages()
        $('.modal-backdrop').show()
        $('body').addClass('modal-open')
      },
      // メッセージウィンドウを閉じる
      onCloseMessageWindow() {
        this.isOpenMessageWindow = false
        $('.modal-backdrop').hide()
        $('body').removeClass('modal-open')
      },
      // メッセージを送信
      onAddMessage() {
        let that = this
        this.createOrderMessage((orderMessage) => {
          that.scrollToOrderMessage(orderMessage.id)
        })
      },
      // ファイル選択
      onChangeFormFile(e) {
        // event(=e)から画像データを取得する
        const file = e.target.files[0]
        const reader = new FileReader()
        // imageをreaderにDataURLとしてattachする
        reader.readAsDataURL(file)
        // readAdDataURLが完了したあと実行される処理
        reader.onload = () => {
          this.formFile = reader.result
          this.formFileName = file.name
          console.log(this.formFile)
        }
      }
    },
  })

  // トークルーム終了ボタン
  $(".js-complete-modal-submit").on('click', (e) => {
    let modalId = $(e.target).data('modal-id')
    let checked = $('input[name="complete-' + modalId + '"]:checked').val()

    if (checked == 'complete') {
      //  完了
      $('form#form-complete-' + modalId + ' .submit').click()
    } else {
      //  キャンセル
      $('form#form-cancel-' + modalId + ' .submit').click()
    }
  })
})
