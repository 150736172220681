import Vue from 'vue'
import draggable from 'vuedraggable'
import axios from 'axios'

$(() => {
  if (!$('#admin-json_generators_index').length) {
    return
  }

  const setupComponent = (id) => {
    new Vue({
      el: id,
      components: {
        'draggable': draggable,
      },
      data: {
        attrs: [], // key, label, type, ...
        formConfigs: [],
        formJson: "" 
      },
      mounted() {
        let that = this

        // setup
        this.fetchAttrs(() => {
          this.setupFormJson()
        })

        // set jquery event
        $('#attr-modal .close').on('click', () => {
          that.fetchAttrs() 
        })
      },
      methods: {
        fetchAttrs(callback) {
          let params = {
            site_id: $(id).data('site_id'),
            attr: $(id).data('attr')
          }
          axios
            .get('/api/v1/attrs', {
              params: params
            })
            .then(resp => {
              this.setAttrs(resp.data)
              callback()
            })
        },
        setAttrs(data) {
          this.attrs = []
          for (let label_key in data) {
            if (label_key.includes('_label')) { // labelのみ扱う
              let label = data[label_key]
              if (!!label) { // labelがnullでなければ扱う
                let key = label_key.replace('_label', "")
                this.attrs.push({
                  key: key,
                  label: label,
                  type: data[`${key}_type`]
                })
              }
            }
          }
        },
        configFormatted(configs) {
          let params = []

          for (let conf of configs) {
            const param = Object.assign({}, conf);
            param.data = JSON.parse(conf.data)
            params.push(param)
          }

          return JSON.stringify({
            "default": {
              "form_group": [
                {
                  "form": params 
                }
              ]
            }
          })
        },
        onSubmit() {
          this.formJson = this.configFormatted(this.formConfigs)
        },
        /**********************************************************
         * Formイベント
         **********************************************************/
        setupFormJson() {
          let defaultJson = $(id).data('form_json')
          console.log(defaultJson)
          for (let form of defaultJson.default.form_group[0].form) {
            form.data = JSON.stringify(form.data)
            this.formConfigs.push(form)
          }
          this.updateOrginConfig(this.formConfigs)
          
        },
        dispPresence(conf) {
          let data = JSON.parse(conf.data)
          return data.rules.includes('presence')
        },
        // デフォルトの値を補完する
        updateOrginConfig(configs) {
          for (let conf of configs) {
            for (let attr of this.attrs) {
              if (conf.key === attr.key) {
                conf.originLabel = attr.label
                conf.originType = attr.type
              }
            }
          }
        },
        isSelectedAttrToForm(attr) {
          for (let conf of this.formConfigs) {
            if (conf.key === attr.key) {
              return true
            }
          }
          return false
        },
        onAddAttrToForm(attr) {
          if (this.isSelectedAttrToForm(attr)) {
            return
          }
          this.formConfigs.push({
            originLabel: attr.label,
            originType: attr.type,
            key: attr.key,
            label: "",
            type: "",
            placeholder: "",
            hint: "",
            disabled: "",
            is_editable_by_only_owner: false,
            data: '{ "rules": [ "presence" ] }',
            default_value: "",
            unit_label: "", // number専用
            html: "", // check_required
            true: "", // is_orderable専用
            false: "", // is_orderable専用
            to_key: "",  // period専用
            sns_list: "", // sns_urls専用
          })
        },
        onDeleteAttrFromForm(index) {
          this.formConfigs.splice(index, 1)
        },
      },
    })
  }

  
  setupComponent('#js-generator')
})
