import Vue from 'vue'

$(() => {
  if (!$('#multiple-category-select-modal').length) {
    return
  }

  // memo
  // #multiple-category-select-modal > モーダル
  // #multiple-category-select-modal_open> エリアモーダルを開く
  // #multiple-category-select-modal_value > エリア選択された値を表示するinput
  // #multiple-category-select-modal_params > エリア選択された値のhidden input

  const itemSearchModalCategory = (id) => {
    new Vue({
      el: id,
      data: {
        openedCategoryGroupId: "",
        selectedCategoryIds: [],
        datas: $(id).data('data'), 
      },
      mounted() {
        this.openedCategoryGroupId = this.datas[0].group.id
        let params = $('#multiple-category-select-modal_params').val()
        for (let param of params.split(',')) {
          if (param) {
            this.selectedCategoryIds.push(parseInt(param))
          }
        }
        this.updateParams()
      },
      methods: {
        dispIsSelectedAllCategory(categories) {
          for (let category of categories) {
            if (!this.selectedCategoryIds.includes(category.id)) {
              return false
            }
          }
          return true
        },
        dispSelectedCountInCategoryGroup(categoryGroupId) {
          let count = 0
          for (let data of this.datas) {
            if (data.group.id !== categoryGroupId) {
              continue
            }
            for (let category of data.categories) {
              if (this.selectedCategoryIds.includes(category.id)) {
                count += 1
              }
            }
          }
          return count
        },
        updateFormAction() {
           // /items/categoriesならformのリクエスト先を/itemsに変える
           if (location.pathname.match("/items/categories")) {
            let form = $('#multiple-category-select-modal_params').closest('form')
            let action = form.attr('action')
            form.attr('action', "/" + action.split('/')[1])
           }
        },
        updateParams() {
          // 表示用
          let values = []
          for (let data of this.datas) {
            for (let category of data.categories) {
              if (this.selectedCategoryIds.includes(category.id)) {
                values.push(category.name)
              }
            }
          }
          $('#multiple-category-select-modal_value').val(values.join('、'))

          // パラメータ用
          let params = [] 
          for (let select of this.selectedCategoryIds) {
            params.push(select)
          }
          $('#multiple-category-select-modal_params').val(params.join(','))
        },
        onSelectCategoryGroup(categoryGroupId) {
          this.openedCategoryGroupId = categoryGroupId
        },
        onSelectAllCategory(categories) {
          if (this.dispIsSelectedAllCategory(categories)) {
            // 既に選択済みの場合はすべてチェック外す
            for (let category of categories) {
              this.selectedCategoryIds.splice(this.selectedCategoryIds.indexOf(category.id), 1)
            }
            this.updateParams()
            this.updateFormAction()
            return
          }

          // 未選択の場合

          for (let category of categories) {
            if (this.selectedCategoryIds.includes(category.id)) {
              // チェック済みはスキップ
            } else {
              // 未チェックは追加
              this.selectedCategoryIds.push(category.id)
            }
          }

          // 最後にソート
          this.selectedCategoryIds.sort((a,b) => {
              if(a.sort > b.sort) return -1;
              if(a.sort < b.sort) return 1;
              return 0;
          });

          this.updateParams()
          this.updateFormAction()
        },
        onSelectCategory(category) {
          if (this.selectedCategoryIds.includes(category.id)) {
            // チェック済みは削除
            this.selectedCategoryIds.splice(
              this.selectedCategoryIds.indexOf(category.id), 1)
          } else {
            // 未チェックは追加
            this.selectedCategoryIds.push(category.id)
            this.selectedCategoryIds.sort((a,b) => {
                if(a.sort > b.sort) return -1;
                if(a.sort < b.sort) return 1;
                return 0;
            });
          }
          this.updateParams()
          this.updateFormAction()
        },
      }
    })
  }

  // モーダル表示イベント
  $('#multiple-category-select-modal_open').on('click', () => {
    $('#multiple-category-select-modal_value').blur()
    $('#multiple-category-select-modal').modal('show')
  })
  
  // 初期化
  itemSearchModalCategory('#multiple-category-select-modal')
})
