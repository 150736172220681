import Vue from 'vue'

$(() => {
  if (!$('.js-multiple-select-modal').length) {
    return
  }

  // memo
  // .js-multiple-select-modal > モーダル
  // .js-multiple-select-modal_wrapper > wrapper
  // .js-multiple-select-modal_open> モーダルを開く
  // .js-multiple-select-modal_value > エリア選択された値を表示するinput
  // .js-multiple-select-modal_params > エリア選択された値のhidden input

  const setupComponent = (id) => {

    const wrapper  = $(id).closest('.js-multiple-select-modal_wrapper')
    const opener = wrapper.find('.js-multiple-select-modal_open')
    const valueInput = wrapper.find('.js-multiple-select-modal_value')

    // // モーダル表示イベント
    opener.on('click', (e) => {
      $(id).modal('show')
    })

    new Vue({
      el: id,
      data: {
        selectedChoiceIds: [],
        datas: $(id).data('data'), 
      },
      mounted() {
        let values = $(id).data('default')
        if (!!values) {
          for (let val of values) {
            if (val) {
              this.selectedChoiceIds.push(parseInt(val))
            }
          }
        }
        this.updateParams()
      },
      methods: {
        dispIsSelectedAll(choices) {
          for (let choice of choices) {
            if (!this.selectedChoiceIds.includes(choice.id)) {
              return false
            }
          }
          return true
        },
        dispSelectedCountInChoice(choiceId) {
          let count = 0
          for (let data of this.datas) {
            if (data.choice.id !== choiceId) {
              continue
            }
            for (let choice of data.choices) {
              if (this.selectedChoiceIds.includes(choice.id)) {
                count += 1
              }
            }
          }
          return count
        },
        updateParams() {
          // 表示用
          let values = []
          for (let choice of this.datas) {
            if (this.selectedChoiceIds.includes(choice.id)) {
              values.push(choice.name)
            }
          }
          valueInput.val(values.join('、'))
        },
        onSelectAll(choices) {
          if (this.dispIsSelectedAll(choices)) {
            // 既に選択済みの場合はすべてチェック外す
            for (let choice of choices) {
              this.selectedChoiceIds.splice(this.selectedChoiceIds.indexOf(choice.id), 1)
            }
            this.updateParams()
            return
          }

          // 未選択の場合

          for (let choice of choices) {
            if (this.selectedChoiceIds.includes(choice.id)) {
              // チェック済みはスキップ
            } else {
              // 未チェックは追加
              this.selectedChoiceIds.push(choice.id)
            }
          }

          // 最後にソート
          this.selectedChoiceIds.sort((a,b) => {
              if(a.sort > b.sort) return -1;
              if(a.sort < b.sort) return 1;
              return 0;
          });

          this.updateParams()
        },
        onSelect(choice) {
          if (this.selectedChoiceIds.includes(choice.id)) {
            // チェック済みは削除
            this.selectedChoiceIds.splice(
              this.selectedChoiceIds.indexOf(choice.id), 1)
          } else {
            // 未チェックは追加
            this.selectedChoiceIds.push(choice.id)
            this.selectedChoiceIds.sort((a,b) => {
                if(a.sort > b.sort) return -1;
                if(a.sort < b.sort) return 1;
                return 0;
            });
          }
          this.updateParams()
        },
      }
    })
  }


  // 初期化
  $('.js-multiple-select-modal').each((i, element) => {
    let id = $(element).attr('id')
    setupComponent('#' + id)
  })
})
